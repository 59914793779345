import React from 'react';

import { Segment, List, Container, Header } from 'semantic-ui-react';

function FooterComponent(props) {
  return (
    <Segment className="footer" inverted vertical>
      <Container textAlign="center">
        <Header inverted as="h4" content="Footer Header" />
        <List horizontal inverted divided link size="small">
          <List.Item as="a" href="#">
            Site Map
          </List.Item>
          <List.Item as="a" href="mailto:noreply@nylend.net">
            Contact Us
          </List.Item>
          <List.Item
            as="a"
            href="https://github.com/nylend95/money-tracker-client"
          >
            GitHub
          </List.Item>
        </List>
      </Container>
    </Segment>
  );
}

export default FooterComponent;
