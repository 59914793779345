import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form, Segment, Dimmer, Loader } from 'semantic-ui-react';

import {
  transactionCreateAction,
  orgsGetAllAction,
  categoryGetAllAction,
} from '../../state/actions';

class AddExpense extends Component {
  state = {
    orgId: 0,
    categoryId: 0,
    amount: 0,
    memo: '',
  };

  componentDidMount() {
    this.props.categoryGetAllAction();
    this.props.orgsGetAllAction();
  }

  handleChange = (e, { name, value }) => this.setState({ [name]: value });

  handleSubmit = () => {
    const { orgId, categoryId, amount, memo } = this.state;

    let expense = {
      orgId: orgId,
      entries: [
        {
          categoryId: [categoryId],
          memo: memo,
          amount: amount,
        },
      ],
    };
    this.props.transactionCreateAction(expense);
  };

  render() {
    const { transactionState, organisations, categories } = this.props;
    const { loading } = transactionState;

    if (loading) {
      return (
        <Dimmer active>
          <Loader>Loading</Loader>
        </Dimmer>
      );
    }
    return (
      <Segment>
        <Form onSubmit={this.handleSubmit}>
          <Form.Select
            label="Organization"
            name="orgId"
            placeholder="Choose an organization"
            options={organisations.map((element, i) => ({
              key: element.id,
              value: element.id,
              text: element.name,
            }))}
            onChange={this.handleChange}
          />

          <Form.Group>
            <Form.Select
              label="Category"
              name="categoryId"
              placeholder="Choose a category"
              options={categories
                .filter(c => c.organisationId === this.state.orgId)
                .map(c => {
                  return {
                    key: c.id,
                    value: c.id,
                    text: c.name,
                  };
                })}
              onChange={this.handleChange}
            />
            <Form.Input
              label="Amount"
              name="amount"
              type="number"
              placeholder="Amount"
              onChange={this.handleChange}
            />
            <Form.Input
              label="Memo"
              name="memo"
              type="text"
              placeholder="Description"
              onChange={this.handleChange}
            />
          </Form.Group>
          <Form.Button content="Add" />
        </Form>
      </Segment>
    );
  }
}

const mapStateToProps = state => {
  return {
    organisations: state.orgState.orgs,
    categories: state.categoryState.categories,
    transactionState: state.transactionState,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      transactionCreateAction,
      categoryGetAllAction,
      orgsGetAllAction,
    },
    dispatch
  );
}

AddExpense = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddExpense);

export default AddExpense;
