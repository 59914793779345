import { CATEGORIES_GET_ALL, CATEGORIES_CREATE } from '../types';
import { get, post } from '../../global';

const endpoint = '/api/category';

const categoryGetAll = payload => ({ type: CATEGORIES_GET_ALL, payload });
const categoryCreate = payload => ({ type: CATEGORIES_CREATE, payload });

export const categoryGetAllAction = () => dispatch => {
  get(endpoint)
    .then(body => {
      return dispatch(categoryGetAll(body));
    })
    .catch(error => {
      console.log(error);
    });
};

export const categoryCreateAction = data =>
  function(dispatch) {
    post(endpoint, data)
      .then(body => dispatch(categoryCreate(body)))
      .catch(error => console.log(error));
  };
