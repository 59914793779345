import {
  LOGIN_LOADING,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT_SUCCESS,
} from '../types';

const initialState = {
  loading: false,
  user: {},
  token: '',
  error: '',
  isLoggedIn: !!localStorage.getItem('auth_token'),
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LOGIN_LOADING:
      const { loading } = action;
      return {
        ...state,
        loading: loading,
      };
    case LOGIN_SUCCESS:
      const { user, token } = action.payload;
      localStorage.setItem('auth_token', token);
      return {
        ...state,
        user: user,
        token: token,
        loading: false,
        isLoggedIn: true,
      };
    case LOGIN_ERROR:
      const { error } = action;
      return { ...state, loading: false, error: error };
    case LOGOUT_SUCCESS:
      localStorage.removeItem('auth_token');
      return {
        ...state,
        user: {},
        loading: false,
        isLoggedIn: false,
      };
    default:
      return state;
  }
}
