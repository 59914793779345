import { MENU_SHOW, MENU_HIDE, MENU_ACTIVE_ITEM } from '../types';

const initialState = {
  isVisible: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case MENU_SHOW:
      return {
        ...state,
        isVisible: action.visible,
      };
    case MENU_HIDE:
      return {
        ...state,
        isVisible: action.visible,
      };
    case MENU_ACTIVE_ITEM:
      return {
        ...state,
        activeMenuItem: action.activeItem,
        isVisible: false,
      };
    default:
      return state;
  }
}
