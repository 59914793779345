import {
  TRANSACTIONS_GET_ALL,
  TRANSACTIONS_CREATE,
  TRANSACTIONS_LOADING,
} from '../types/';

const initialState = {
  loading: false,
  transactions: [],
};

export default function(state = initialState, action) {
  const payload = action.payload;
  switch (action.type) {
    case TRANSACTIONS_GET_ALL:
      return {
        ...state,
        loading: false,
        transactions: payload,
      };
    case TRANSACTIONS_CREATE:
      return {
        ...state,
        loading: false,
        // Add newly created instead of fetching all over
        transactions: [...state.transactions, payload],
      };
    case TRANSACTIONS_LOADING:
      return {
        ...state,
        loading: payload,
      };
    default:
      return state;
  }
}
