import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { categoryGetAllAction } from '../../state/actions';
import CategoryForm from './CategoryForm';

function Categories({ categories, fetchAllCategories }) {
  useEffect(() => {
    fetchAllCategories();
  }, [fetchAllCategories]);

  return (
    <React.Fragment>
      <h2>Categories</h2>
      <CategoryForm isCreate />
      <ul>
        {categories.map((element, i) => (
          <li key={i}>
            {element.name} (org id: {element.organisationId})
          </li>
        ))}
      </ul>
    </React.Fragment>
  );
}

const mapStateToProps = state => {
  return {
    categories: state.categoryState.categories,
  };
};

const actionTypes = {
  fetchAllCategories: categoryGetAllAction,
};

export default connect(
  mapStateToProps,
  actionTypes
)(Categories);
