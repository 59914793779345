import React, { Component } from 'react';
import { Provider } from 'react-redux';

import { store } from './state/store';
import Root from './components/Root';

import './moneyTracker.css';

class MoneyTracker extends Component {
  render() {
    return (
      <Provider store={store}>
        <Root />
      </Provider>
    );
  }
}

export default MoneyTracker;
