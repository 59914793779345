import { createStore, applyMiddleware, compose } from 'redux';
//import logger from 'redux-logger';
import thunk from 'redux-thunk';

import { rootReducer } from '../reducers';

var middlewares = [];

middlewares.push(thunk);

if (process.env.NODE_ENV === 'development') {
  // middlewares.push(logger);
}

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  rootReducer,
  composeEnhancer(applyMiddleware(...middlewares))
);
