import { CATEGORIES_GET_ALL, CATEGORIES_CREATE } from '../types/';

const initialState = {
  categories: [],
};

export default function(state = initialState, action) {
  const payload = action.payload;
  switch (action.type) {
    case CATEGORIES_GET_ALL:
      return {
        ...state,
        categories: payload,
      };
    case CATEGORIES_CREATE:
      return {
        ...state,
        // Add newly created instead of fetching all over
        categories: [...state.categories, payload],
      };
    default:
      return state;
  }
}
