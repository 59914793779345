import React, { useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Home from './Home';
import Login from './Login';
import AddExpense from './transaction/AddExpense';
import FallBackPage from './FallBackPage';
import Organisations from './organisation/Organisations';
import ProtectedRoute from './ProtectedRoute';
import { connect } from 'react-redux';

import { refreshAuthToken } from '../state/actions';
import MainLayout from './layout/MainLayout';
import Categories from './category/Categories';
import Transactions from './transaction/Transaction';

function Root({ isLoggedIn, dispatch }) {
  useEffect(() => {
    if (isLoggedIn) {
      dispatch(refreshAuthToken());
    }
  }, [isLoggedIn, dispatch]);

  return (
    <BrowserRouter>
      <MainLayout>
        <Switch>
          <ProtectedRoute exact path="/addExpense" component={AddExpense} />
          <ProtectedRoute
            exact
            path="/organisations"
            component={Organisations}
          />
          <ProtectedRoute exact path="/transactions" component={Transactions} />
          <ProtectedRoute exact path="/categories" component={Categories} />
          <ProtectedRoute exact path="/home" component={Home} />
          <Route exact path="/about" component={FallBackPage} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/" component={Home} />
          <Route component={FallBackPage} />
        </Switch>
      </MainLayout>
    </BrowserRouter>
  );
}

const mapStateToProps = state => {
  return {
    isLoggedIn: state.userState.isLoggedIn,
  };
};

export default connect(mapStateToProps)(Root);
