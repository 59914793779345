import { Form } from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form';
import React from 'react';
import { connect } from 'react-redux';
import { loginAction } from '../state/actions';

function LoginForm({ handleSubmit, pristine, submitting }) {
  return (
    <Form onSubmit={handleSubmit}>
      <Form.Field>
        <label>Username</label>
        <div>
          <Field
            name="username"
            component="input"
            type="text"
            placeholder="Username"
          />
        </div>
      </Form.Field>
      <Form.Field>
        <label>Password</label>
        <div>
          <Field
            name="password"
            component="input"
            type="password"
            placeholder="Password"
            autoComplete="password"
          />
        </div>
      </Form.Field>

      <div>
        <Form.Button primary type="submit" disabled={pristine || submitting}>
          Log in
        </Form.Button>
      </div>
    </Form>
  );
}

function submitlogin(values, dispatch, props) {
  props.loginAction(values);
  return true;
}
const mapDispatchToProps = {
  loginAction,
};

export default connect(
  null,
  mapDispatchToProps
)(
  reduxForm({
    form: 'loginForm',
    enableReinitialize: true,
    onSubmit: submitlogin,
  })(LoginForm)
);
