import {
  ORG_LOADING,
  ORGS_GET_ALL,
  ORG_GET,
  ORG_CREATE,
  ORG_ERROR,
  ORG_UPDATE,
  ORG_CREATE_MODAL_TOGGLE,
} from '../types';

const initialState = {
  orgs: [],
  loading: false,
  error: '',
  modalOpen: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ORG_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case ORGS_GET_ALL:
      return {
        ...state,
        orgs: action.payload,
        loading: false,
      };
    case ORG_GET:
      let { orgs } = state;

      if (action.payload) {
        orgs = replaceOrAppend(orgs, action.payload);
      }

      return {
        ...state,
        orgs: orgs,
        loading: false,
      };
    case ORG_CREATE:
      let organisations = state.orgs;

      if (action.payload) {
        organisations = replaceOrAppend(organisations, action.payload);
      }

      return {
        ...state,
        orgs: organisations,
        loading: false,
        modalOpen: false,
      };
    case ORG_UPDATE:
      organisations = state.orgs;

      if (action.payload) {
        organisations = replaceOrAppend(organisations, action.payload);
      }

      return {
        ...state,
        orgs: organisations,
        loading: false,
        modalOpen: false,
      };
    case ORG_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case ORG_CREATE_MODAL_TOGGLE:
      return {
        ...state,
        modalOpen: action.val,
      };
    default:
      return state;
  }
}

const replaceOrAppend = (orgs, org) => {
  const index = orgs.map(o => o.id).indexOf(org.id);

  if (~index) {
    orgs[index] = org;
  } else {
    orgs = orgs.concat(org);
  }

  return orgs;
};
