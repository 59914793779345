import React from 'react';
import { connect } from 'react-redux';

function Home({ user }) {
  return <h3>Hello, {user.displayName}</h3>;
}

const mapStateToProps = state => {
  return {
    user: state.userState.user,
  };
};

export default connect(
  mapStateToProps,
  null
)(Home);
