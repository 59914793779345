import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Table, Button, Segment, Icon } from 'semantic-ui-react';

import OrganisationsForm from './OrganisationForm';

import { orgsGetAllAction } from '../../state/actions';

class Organisations extends Component {
  componentDidMount() {
    this.props.orgsGetAllAction();
  }

  handleNewOrg() {
    return <OrganisationsForm />;
  }

  render() {
    const { orgState } = this.props;
    const { orgs } = orgState;
    return (
      <React.Fragment>
        <h3>Organisations</h3>

        <OrganisationsForm isCreate />

        <Button.Group>
          <Button negative size="tiny">
            <Icon name="delete" />
            Delete
          </Button>
        </Button.Group>

        {organisationsFragment(orgs)}
      </React.Fragment>
    );
  }
}

function organisationsFragment(orgs) {
  if (orgs !== undefined && orgs.length === 0) {
    return <div />;
  }

  return (
    <Segment basic>
      <Table selectable unstackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Description</Table.HeaderCell>
            <Table.HeaderCell>Default</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {orgs.map(org => {
            const {
              id, //TODO fix this on create new
              name,
              description,
              isDefault,
            } = org;
            return (
              <Table.Row key={id} className="clickable-table-row">
                <Table.Cell>{name}</Table.Cell>
                <Table.Cell>{description}</Table.Cell>
                <Table.Cell>
                  {isDefault ? <Icon name="checkmark" /> : ''}
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </Segment>
  );
}

const mapStateToProps = state => {
  return {
    userState: state.userState,
    orgState: state.orgState,
  };
};

function mapDispatchToProps(dispatch) {
  let actions = {
    orgsGetAllAction,
  };

  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Organisations);
