import {
  TRANSACTIONS_GET_ALL,
  TRANSACTIONS_CREATE,
  TRANSACTIONS_LOADING,
} from '../types';
import { get, post } from '../../global';

const endpoint = '/api/transaction';

const transactionLoading = payload => ({ type: TRANSACTIONS_LOADING, payload });
const transactionGetAll = payload => ({ type: TRANSACTIONS_GET_ALL, payload });
const transactionCreate = payload => ({ type: TRANSACTIONS_CREATE, payload });

export const transactionGetAllAction = () => dispatch => {
  get(endpoint)
    .then(body => {
      return dispatch(transactionGetAll(body));
    })
    .catch(error => {
      console.log(error);
    });
};

export const transactionCreateAction = data =>
  function(dispatch) {
    dispatch(transactionLoading(true));
    post(endpoint, data)
      .then(body => dispatch(transactionCreate(body)))
      .catch(error => console.log(error));
  };
