import axios from 'axios';

import {
  LOGIN_LOADING,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT_SUCCESS,
} from '../types';
import { baseURL } from '../../global';

const loginLoading = loading => ({ type: LOGIN_LOADING, loading });
const loginSuccess = payload => ({ type: LOGIN_SUCCESS, payload });
const loginError = error => ({ type: LOGIN_ERROR, error });

const logoutSuccess = () => ({ type: LOGOUT_SUCCESS });

export const loginAction = loginData => dispatch => {
  dispatch(loginLoading(true));

  const endpoint = '/api/auth/login';
  let url = baseURL + endpoint;

  axios
    .post(url, JSON.stringify(loginData), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => {
      return res.data;
    })
    .then(body => {
      return dispatch(loginSuccess(body));
    })
    .catch(error => {
      return dispatch(loginError(error));
    });
};

export const logoutAction = () => dispatch => {
  return dispatch(logoutSuccess());
};

export const refreshAuthToken = () => dispatch => {
  dispatch(loginLoading(true));

  const endpoint = '/api/auth/refresh';
  let url = baseURL + endpoint;
  const token = localStorage.getItem('auth_token');

  axios
    .get(url, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
    .then(res => {
      return res.data;
    })
    .then(body => {
      return dispatch(loginSuccess(body));
    })
    .catch(error => {
      console.log(`Error status code: ${error.request.status}`);
      if (error.request.status === 401) {
        dispatch(logoutSuccess());
      } else {
        dispatch(loginError(error));
      }
    });
};
