import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Table, Button, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import {
  transactionGetAllAction,
  categoryGetAllAction,
  orgsGetAllAction,
  menuActiveItem,
} from '../../state/actions';

class Transactions extends Component {
  componentDidMount() {
    this.props.transactionGetAllAction();
    this.props.categoryGetAllAction();
    this.props.orgsGetAllAction();
  }

  render() {
    const { orgState, transactionState, categoryState } = this.props;
    const { orgs } = orgState;
    const { transactions } = transactionState;
    const { categories } = categoryState;

    return (
      <React.Fragment>
        <h3>Transactions</h3>

        <Button.Group>
          <Button
            positive
            size="tiny"
            onClick={() => this.props.menuActiveItem('addExpense')}
            as={Link}
            to="/addExpense"
          >
            <Icon name="add" />
            Add
          </Button>
        </Button.Group>

        {transactionsFragment(transactions, categories, orgs)}
      </React.Fragment>
    );
  }
}

function transactionsFragment(transactions, categories, orgs) {
  if (transactions !== undefined && transactions.length === 0) {
    return <div />;
  }

  return (
    <Table sortable unstackable structured>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell rowSpan="2" textAlign="center">
            Org
          </Table.HeaderCell>
          <Table.HeaderCell rowSpan="2" textAlign="center">
            Date
          </Table.HeaderCell>
          <Table.HeaderCell colSpan="2" textAlign="center">
            Entries
          </Table.HeaderCell>
        </Table.Row>
        <Table.Row>
          <Table.HeaderCell textAlign="center">Amount</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Category</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {transactions.map(t => {
          const { id, date, orgId, entries } = t;
          const d = new Date(date);
          const dateToShow =
            d.getDay() +
            '/' +
            d.getMonth() +
            '-' +
            d.getFullYear() +
            ' ' +
            d.getHours() +
            ':' +
            d.getMinutes();
          const org = orgs.filter(o => o.id === orgId);
          return (
            <Table.Row key={id}>
              <Table.Cell>
                {org.length > 0 ? org[0].name : 'Failed to load Organisation'}
              </Table.Cell>
              <Table.Cell>{dateToShow}</Table.Cell>
              <Table.Cell colSpan="2">
                {entriesFragment(entries, categories)}
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
}

function entriesFragment(entries, categories) {
  if (entries !== undefined && entries.length === 0) {
    return <div />;
  }

  return (
    <Table selectable unstackable>
      <Table.Body>
        {entries.map(e => {
          const {
            id, //TODO fix this on create new
            amount,
            categoryId,
          } = e;
          const category = categories.filter(c => Number(categoryId) === c.id);
          return (
            <Table.Row key={id} className="clickable-table-row">
              <Table.Cell>{amount} NOK</Table.Cell>
              <Table.Cell>
                {category.length > 0 ? category[0].name : ''}
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
}

const mapStateToProps = state => {
  return {
    userState: state.userState,
    transactionState: state.transactionState,
    orgState: state.orgState,
    categoryState: state.categoryState,
  };
};

function mapDispatchToProps(dispatch) {
  let actions = {
    transactionGetAllAction,
    categoryGetAllAction,
    orgsGetAllAction,
    menuActiveItem,
  };

  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Transactions);
