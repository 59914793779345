import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

function ProtectedRoute(props) {
  let {
    component: ComponentToShow,
    isLoggedIn,
    redirectUrl = '/',
    ...rest
  } = props;
  let render = () =>
    isLoggedIn ? <ComponentToShow {...props} /> : <Redirect to={redirectUrl} />;
  return <Route {...rest} render={render} />;
}

ProtectedRoute.propTypes = {
  redirectUrl: PropTypes.string,
  component: PropTypes.func,
};

const mapStateToProps = state => ({
  isLoggedIn: state.userState.isLoggedIn,
});

export default connect(mapStateToProps)(ProtectedRoute);
