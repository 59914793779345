import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  orgCreateAction,
  orgUpdateAction,
  openModalOrgCreate,
  closeModalOrgCreate,
} from '../../state/actions';
import { bindActionCreators } from 'redux';
import { Field, reduxForm } from 'redux-form';

import { Loader, Form, Modal, Icon, Header, Button } from 'semantic-ui-react';

class OrganisationForm extends Component {
  handleClose() {}

  handleOpen() {
    console.log(this);
  }

  render() {
    const {
      isCreate,
      handleSubmit,
      pristine,
      submitting,
      orgState,
    } = this.props;

    const actionButtonText = isCreate ? 'Create' : 'Update';

    const { loading, modalOpen } = orgState;

    if (loading === true) {
      return <Loader />;
    }

    return (
      <Modal
        trigger={
          <Button primary onClick={this.props.openModalOrgCreate}>
            <Icon name="plus" />
            New
          </Button>
        }
        open={modalOpen}
        closeIcon
        onClose={this.props.closeModalOrgCreate}
        size="small"
      >
        <Header icon="block layout" content="Organisation" />
        <Modal.Content>
          <Form onSubmit={handleSubmit}>
            <Form.Field>
              <label>Name</label>
              <div>
                <Field
                  name="name"
                  component="input"
                  type="text"
                  placeholder="Name"
                />
              </div>
            </Form.Field>
            <Form.Field>
              <label>Description</label>
              <div>
                <Field
                  name="description"
                  component="input"
                  type="text"
                  placeholder="Description"
                />
              </div>
            </Form.Field>
            <Form.Button
              primary
              type="submit"
              disabled={pristine || submitting}
            >
              {actionButtonText}
            </Form.Button>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}

function submitForm(values, dispatch, props) {
  console.log('orgForm props: ', props);

  if (props.isCreate) {
    props.orgCreateAction(values);
  } else {
    props.orgUpdateAction(values);
  }

  return true;
}

const mapStateToProps = state => {
  return {
    orgState: state.orgState,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      orgCreateAction,
      orgUpdateAction,
      openModalOrgCreate,
      closeModalOrgCreate,
    },
    dispatch
  );
}

OrganisationForm = reduxForm({
  form: 'orgForm',
  enableReinitialize: true,
  onSubmit: submitForm,
})(OrganisationForm);
OrganisationForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganisationForm);
export default OrganisationForm;
