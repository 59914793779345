import { MENU_SHOW, MENU_HIDE, MENU_ACTIVE_ITEM } from '../types';

const menuShow = visible => ({ type: MENU_SHOW, visible });
const menuHide = visible => ({ type: MENU_HIDE, visible });

const setMenuActiveItem = activeItem => ({
  type: MENU_ACTIVE_ITEM,
  activeItem,
});

export const menuShowHide = visible => dispatch => {
  if (visible) {
    return dispatch(menuHide(!visible));
  } else {
    return dispatch(menuShow(!visible));
  }
};

export const menuActiveItem = activeItem => dispatch => {
  return dispatch(setMenuActiveItem(activeItem));
};
