import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { Loader } from 'semantic-ui-react';
import LoginForm from './LoginForm';

//TODO: add error handling.
function Login({ loading, isLoggedIn, history }) {
  useEffect(() => {
    if (isLoggedIn) {
      history.replace('/organisations');
    }
  }, [isLoggedIn, history]);

  const ComponentToRender = loading ? <Loader active /> : <LoginForm />;
  return ComponentToRender;
}

const mapStateToProps = state => {
  return {
    isLoggedIn: state.userState.isLoggedIn,
    loading: state.userState.loading,
  };
};

export default connect(mapStateToProps)(Login);
