import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Field, reduxForm } from 'redux-form';

import { Form, Modal, Icon, Header, Button } from 'semantic-ui-react';
import { categoryCreateAction } from '../../state/actions';

class CategoryForm extends Component {
  render() {
    const {
      isCreate,
      handleSubmit,
      pristine,
      submitting,
      organisations,
    } = this.props;

    const actionButtonText = isCreate ? 'Create' : 'Update';

    return (
      <Modal
        trigger={
          <Button primary>
            <Icon name="plus" />
            New
          </Button>
        }
        closeIcon
        size="small"
      >
        <Header icon="block layout" content="Category" />
        <Modal.Content>
          <Form onSubmit={handleSubmit}>
            <Form.Field>
              <label>Name</label>
              <div>
                <Field
                  name="name"
                  component="input"
                  type="text"
                  placeholder="Name"
                />
              </div>
            </Form.Field>
            <Form.Field>
              <label>Organisation</label>
              <Field name="organisationId" component="select">
                <option value="">Choose one...</option>
                {organisations.map(element => (
                  <option key={element.id} value={element.id}>
                    {element.name}
                  </option>
                ))}
              </Field>
            </Form.Field>
            <Form.Button
              primary
              type="submit"
              disabled={pristine || submitting}
            >
              {actionButtonText}
            </Form.Button>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}

function submitForm(values, dispatch, props) {
  console.log('categoryForm props: ', props);
  let data = {
    name: values.name,
    organisationId: values.organisationId,
  };
  console.log(data);
  if (props.isCreate) {
    props.createCategory(data);
  } else {
  }
  return true;
}

const mapStateToProps = state => {
  return {
    organisations: state.orgState.orgs,
  };
};

const actionTypes = {
  createCategory: categoryCreateAction,
};

CategoryForm = reduxForm({
  form: 'categoryForm',
  enableReinitialize: true,
  onSubmit: submitForm,
})(CategoryForm);

CategoryForm = connect(
  mapStateToProps,
  actionTypes
)(CategoryForm);

export default CategoryForm;
