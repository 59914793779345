import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Menu,
  Button,
  Sidebar,
  Icon,
  Segment,
  Container,
} from 'semantic-ui-react';

import {
  menuShowHide,
  menuActiveItem,
  logoutAction,
} from '../../state/actions';
import HeaderComponent from './HeaderComponent';
import FooterComponent from './FooterComponent';

class MenuContainer extends Component {
  renderMenuButtons(isLoggedIn, activeMenuItem) {
    if (isLoggedIn) {
      return (
        <nav>
          <Menu.Item
            active={activeMenuItem === 'home'}
            onClick={() => this.props.menuActiveItem('home')}
            as={Link}
            to="/home"
          >
            <div>
              <Icon name="home" />
              Home
            </div>
          </Menu.Item>
          <Menu.Item
            active={activeMenuItem === 'addExpense'}
            onClick={() => this.props.menuActiveItem('addExpense')}
            as={Link}
            to="/addExpense"
          >
            <div>
              <Icon name="currency" />
              Add Expense
            </div>
          </Menu.Item>
          <Menu.Item
            active={activeMenuItem === 'Transactions'}
            onClick={() => this.props.menuActiveItem('transactions')}
            as={Link}
            to="/transactions"
          >
            <div>
              <Icon name="currency" />
              Transactions
            </div>
          </Menu.Item>
          <Menu.Item
            active={activeMenuItem === 'organisations'}
            onClick={() => this.props.menuActiveItem('organisations')}
            as={Link}
            to="/organisations"
          >
            <div>
              <Icon name="block layout" />
              Organisations
            </div>
          </Menu.Item>
          <Menu.Item
            active={activeMenuItem === 'categories'}
            onClick={() => this.props.menuActiveItem('categories')}
            as={Link}
            to="/categories"
          >
            <div>
              <Icon name="tags" />
              Categories
            </div>
          </Menu.Item>
          <Menu.Item
            active={activeMenuItem === 'charts'}
            onClick={() => this.props.menuActiveItem('charts')}
            as={Link}
            to="/charts"
          >
            <div>
              <Icon name="pie chart" />
              Charts
            </div>
          </Menu.Item>
          <Menu.Item
            active={activeMenuItem === 'settings'}
            onClick={() => this.props.menuActiveItem('settings')}
            as={Link}
            to="/settings"
          >
            <div>
              <Icon name="settings" />
              Settings
            </div>
          </Menu.Item>
          <Menu.Item
            active={activeMenuItem === 'logout'}
            onClick={() => {
              this.props.menuActiveItem('login');
              this.props.logoutAction();
            }}
            as={Link}
            to="/"
          >
            <div>
              <Icon name="user" />
              Log out
            </div>
          </Menu.Item>
          <Menu.Item
            active={activeMenuItem === 'about'}
            onClick={() => this.props.menuActiveItem('about')}
            as={Link}
            to="/about"
          >
            <div>
              <Icon name="info circle" />
              About
            </div>
          </Menu.Item>
        </nav>
      );
    } else {
      return (
        <nav>
          <Menu.Item
            active={activeMenuItem === 'login'}
            onClick={() => this.props.menuActiveItem('login')}
            as={Link}
            to="/login"
          >
            <div>
              <Icon name="user" />
              Log in
            </div>
          </Menu.Item>
          <Menu.Item
            active={activeMenuItem === 'about'}
            onClick={() => this.props.menuActiveItem('about')}
            as={Link}
            to="/about"
          >
            <div>
              <Icon name="info circle" />
              About
            </div>
          </Menu.Item>
        </nav>
      );
    }
  }

  render() {
    const { isVisible, activeMenuItem } = this.props.menuState;
    const { isLoggedIn } = this.props.userState;

    return (
      <div>
        <Sidebar.Pushable as={Segment}>
          <Sidebar
            as={Menu}
            animation="overlay"
            icon="labeled"
            inverted
            onHide={() => this.props.menuShowHide(true)}
            vertical
            visible={isVisible}
            width="thin"
          >
            {this.renderMenuButtons(isLoggedIn, activeMenuItem)}
          </Sidebar>

          <Sidebar.Pusher>
            <Container className="header-container">
              <Button
                floated="left"
                compact
                onClick={() => this.props.menuShowHide(isVisible)}
              >
                <Icon name="bars" />
              </Button>
              <HeaderComponent />
            </Container>
            <Segment className="content-container">
              {this.props.children}
            </Segment>
            <FooterComponent />
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    menuState: state.menuState,
    userState: state.userState,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      menuShowHide,
      menuActiveItem,
      logoutAction,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuContainer);
