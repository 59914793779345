import {
  ORG_LOADING,
  ORGS_GET_ALL,
  //ORG_GET,
  ORG_CREATE,
  ORG_UPDATE,
  ORG_ERROR,
  ORG_CREATE_MODAL_TOGGLE,
} from '../types';

import { get, post, put } from '../../global';

const endpoint = '/api/organisation';

const orgLoading = loading => ({ type: ORG_LOADING, loading });

const orgsGetAll = payload => ({ type: ORGS_GET_ALL, payload });
//const orgGet = (payload) => ({type: ORG_GET, payload});

const orgCreate = payload => ({ type: ORG_CREATE, payload });
const orgUpdate = payload => ({ type: ORG_UPDATE, payload });

const orgError = error => ({ type: ORG_ERROR, error });

const toggleModalOrgCreate = val => ({ type: ORG_CREATE_MODAL_TOGGLE, val });

//Get all organisations for logged in user
export const orgsGetAllAction = () => dispatch => {
  dispatch(orgLoading(true));

  get(endpoint)
    .then(body => {
      return dispatch(orgsGetAll(body));
    })
    .catch(error => {
      return dispatch(orgError(error));
    });
};

//Create Organisation for user
export const orgCreateAction = orgData => dispatch => {
  dispatch(orgLoading(true));

  post(endpoint, orgData)
    .then(body => {
      debugger;
      return dispatch(orgCreate(body));
    })
    .catch(error => {
      return dispatch(orgError(error));
    });
};

export const orgUpdateAction = orgData => dispatch => {
  dispatch(orgLoading(true));

  put(endpoint, orgData)
    .then(body => {
      return dispatch(orgUpdate(body));
    })
    .catch(error => {
      return dispatch(orgError(error));
    });
};

export const openModalOrgCreate = () => dispatch => {
  return dispatch(toggleModalOrgCreate(true));
};

export const closeModalOrgCreate = () => dispatch => {
  return dispatch(toggleModalOrgCreate(false));
};
