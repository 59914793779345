//Login action types
export const LOGIN_LOADING = 'LOGIN_LOADING';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const LOGIN_SET_TRUE = 'LOGIN_SET_TRUE';

//Menu action types
export const MENU_SHOW = 'MENU_SHOW';
export const MENU_HIDE = 'MENU_HIDE';
export const MENU_ACTIVE_ITEM = 'MENU_ACTIVE_ITEM';

//Organisation action types
export const ORG_LOADING = 'ORG_LOADING';
export const ORGS_GET_ALL = 'ORGS_GET_ALL';
export const ORG_GET = 'ORG_GET';
export const ORG_CREATE = 'ORG_CREATE';
export const ORG_ERROR = 'ORG_ERROR';
export const ORG_UPDATE = 'ORG_UPDATE';
export const ORG_CREATE_MODAL_TOGGLE = 'ORG_CREATE_MODAL_TOGGLE';

//Category action type

export const CATEGORIES_GET_ALL = 'CATEGORIES_GET_ALL';
export const CATEGORIES_CREATE = 'CATEGORIES_CREATE';

//Transaction action types
export const TRANSACTIONS_GET_ALL = 'TRANSACTIONS_GET_ALL';
export const TRANSACTIONS_CREATE = 'TRANSACTIONS_CREATE';
export const TRANSACTIONS_LOADING = 'TRANSACTIONS_LOADING';
