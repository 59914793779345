import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import userReducer from './userReducer';
import menuReducer from './menuReducer';
import orgReducer from './orgReducer';
import categoryReducer from './categoryReducer';
import transactionReducer from './transactionReducer';

export const rootReducer = combineReducers({
  form: formReducer,
  userState: userReducer,
  menuState: menuReducer,
  orgState: orgReducer,
  categoryState: categoryReducer,
  transactionState: transactionReducer,
});
