import axios from 'axios';
import { baseURL } from './index';

const GET_CONFIG = token => ({
  headers: {
    Authorization: 'Bearer ' + token,
  },
});

const REQUEST_CONFIG = token => ({
  headers: {
    Authorization: 'Bearer ' + token,
    'Content-Type': 'application/json',
  },
});

export async function get(endpoint) {
  const token = localStorage.getItem('auth_token');

  const res = await axios.get(baseURL + endpoint, GET_CONFIG(token));
  return res.data;
}

export async function request(method, endpoint, data) {
  const token = localStorage.getItem('auth_token');

  const res = await axios({
    method,
    url: baseURL + endpoint,
    data: JSON.stringify(data),
    ...REQUEST_CONFIG(token),
  });

  return res.data;
}

export function post(endpoint, data) {
  return request('POST', endpoint, data);
}

export function put(endpoint, data) {
  return request('PUT', endpoint, data);
}

//Delete is reserved keyword...
export function remove(endpoint, data) {
  return request('DELETE', endpoint, data);
}
